body {
  margin: 0;
  padding: 0;

  color: #FFFFFF;
  background: #090909;

  font-family: 'Poppins';
}
/**
* Main holder for pages
*/
.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
/**
* Apply this class if you need custom scrollbar
*/
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  width: 8px;
  border-radius: 4px;
  background-color: #464646;
}