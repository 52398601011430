.widget_controls {
    flex: 0 22px;
    display: block;
    height: 22px;
    line-height: 22px;

    background: #1F1F1F;
    color: #959595;

    font-size: 12px;
    text-transform: capitalize;
    padding-left: 7px;
    padding-right: 7px;

    &:after {
        clear: both;
    }
}
.widget_controls_caption {
    user-select: none;
    float: left;
    cursor: move;
}
.widget_controls_btn_expand,
.widget_controls_btn_minimize,
.widget_controls_btn_close {
    margin-left: 12px;
    float: right;
}
